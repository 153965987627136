/*
 * @Description: 繁体
 * @Version: 1.0
 * @Autor: ziwei
 * @Date: 2021-08-11 13:46:00
 * @LastEditors: ziwei
 * @LastEditTime: 2021-08-19 10:54:33
 */
module.exports = {
    welcome: '歡迎來到歐賽斯在線交易網站！',
    freeRgster: '免費註冊',
    plsLog: '請登錄',
    logged: '已登錄',
    plsSel: '請選擇',
    location: "您當前位置",
    plsInput: '請輸入貨號或者關鍵字',
    clectSuccess: '收藏成功',
    calCollect: '取消收藏成功',
    collect: "收藏",
    collected: '已收藏',
    addCard: '加购',
    pk: '對比',
    no: '貨號',
    sales: '銷量',
    stock: '庫存',
    commodity: '商品精選',
    releaseTime: '發布時間',
    viewNum: '瀏覽量',
    downNum: '下載量',
    prize: '價格',
    total: '共',
    topMenu: {
        cart: "購物車",
        myOrder: "我的訂單",
        myCollect: "我的收藏",
        // purchase: '批量購',
        mail: '郵件訂閱'
    },
    Inquiry:'詢價',
}
