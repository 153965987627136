/*
 * @Description:
 * @Version: 1.0
 * @Autor: ziwei
 * @Date: 2021-08-11 11:33:58
 * @LastEditors: zmj
 * @LastEditTime: 2022-04-06 11:18:37
 */
module.exports = {
    // 登录页面
    welcome: 'Welcome to osses',
    loginIntro: 'If you already have an account, you can log in using your email address or mobile number',
    accountNum: 'Enter account number',
    userName: 'Please enter mobile number or email',
    passWord: 'Input password',
    pPassword: 'Please input a password',
    autoLogin: 'automatic logon',
    forgetPassword: 'Forget password ?',
    login: 'Sign in',
    loginTip: ' If you do not have an account please go to the registration center ',
    benefit: 'There are many benefits to creating an account: faster access, saving multiple addresses, tracking orders',
    ReNow: 'Register now',

    freeRgster: 'Register',
    plsLog: 'Sign in',
    logged: 'logged in',
    plsSel: 'Please select',
    plsInput: 'Please enter Catalog Number or keyword',
    location: 'Your current location',
    clectSuccess: 'Collection Successful',
    calCollect: 'Cancel Collection Successfully',
    collect: "Favorite",
    collected: 'Colledted',
    addCard: 'Add',
    pk: 'PK',
    no: 'Cat.No',
    sales: 'sales',
    stock: 'stock',
    commodity: 'RECOMMEND',
    releaseTime: 'Release Time',
    viewNum: 'View Num',
    downNum: 'Downloads Num',
    price: 'Price',
    total: 'Total',
    page:'Page',
    jumpTo:'Jump to',
    topMenu: {
        cart: 'Cart',
        myOrder: 'My order',
        myCollect: 'My collect',
        // purchase: "Bulk purchase",
        mail: "Mail subscription"
    },
    myCar: "My shopping cart",
    myOrder: 'My order',
    myCollection: 'My collection',
    myFootprint: 'My footprint',
    myInvoice: 'My invoice',
    openBilling: 'Billing information ',
    invoiceAdmin: 'Invoice management',

    // 注册页面
    createAccount: 'Create a new account',
    newCome: 'Novice on the road ?',
    reBenefits1: 'Sign up for free Express',
    reBenefits2: 'Faster payment',
    reBenefits3: 'Multiple shipping addresses can be saved',
    reBenefits4: 'View and track orders',
    accountReg: 'Account registration',
    method1: 'Mobile registration',
    method2: 'Mailbox registration',
    phone: 'phone',
    phoneReg: 'Please enter a valid mobile phone number',
    email: 'E-mail：',
    emailReg: 'Please enter email address',
    password: 'password',
    passwordReg: 'The password must contain 6 to 12 letters and digits',
    surePassword: 'Confirm',
    Confirm: 'Consistent with password',
    testCode: 'Code',
    inputCode: 'Please Enter the verification code',
    getCode: 'Get code',
    agree: 'I have read and agree ',
    agreement: 'Registration Agreement',
    Reg: 'Register now',
    haveAccount: 'Existing account ? Direct ',
    log: 'login',

    function: 'All functions',
    companyAddress: 'Wisdom Valley Business Park, 321 Jinpeng Street, Xihu District, Hangzhou, Zhejiang, China',
    detailedAddress: '',
    technical:'Technical support',
    salesService:'Sales service',
    officialQRCode:'Official QR code',

    homePage: 'Home Page',
    aSetting: 'Account settings',
    keyWords: 'Please enter keyword',
    data: 'personal data',
    // address: 'Receiving address',
    changeP: 'Change Password',
    changePhone: 'Modify mobile phone',
    mailboxs: 'Modify mailbox',
    exitLogin: 'Log out',
    position: 'Your current location',
    head: 'My head',
    name: 'Real name',
    nickname: 'nickname',
    Gender: 'Gender',
    male: 'male',
    female: 'female',
    save: 'Save changes',
    addressInfo: 'Address',
    detail: 'Detailed address',
    addressTip: 'Please enter the detailed address, road, community, house number, etc',
    ConsigneeName: 'Consignee Name',
    iptName: 'Please enter your name',
    phoneTip: 'Phone / mobile number is required',
    setDefault: 'Set as default shipping address',
    saveAddress: 'Save address',
    newP: 'New password',
    boundP: 'Please enter the bound mobile phone number',
    newPassword: 'Please enter a new password',
    newPassword1: 'Please enter the new password again',
    sureChange: 'confirm change',
    secondGet: 'Retrieve in seconds',
    newPhone: 'New mobile',
    iptNewPhone: 'Please enter a new phone number',
    newEmail: 'New mailbox',
    iptNewEmail: 'Please enter a new mailbox',
    laboratory: 'laboratory',
    direction: 'research direction',
    userAddress: 'address',
    compNames: 'Company',
    iptComName: 'Please enter company name',
    // 订单
    productInfo: 'Product information',
    unitPrice1: 'Unit Price',
    quantity: 'Purchase',
    totalCount: 'total',
    state: 'state',
    operation: 'operation',
    orderNum: 'order number',
    pendingPay: 'Pending payment',
    Paid: 'Paid',
    Consignment: 'Consignment',
    ViewLogistics: 'View Logistics',
    ExpeditingDelivery: 'Expediting delivery',
    cancelOrder: 'cancellation of order',
    ImmediatePayment: 'Immediate payment',
    deleteOrder: 'Delete order',
    ApplyInvoic: 'Apply for invoicing',
    Cancelled: 'Cancelled',
    ConfirmReceipt: 'Confirm receipt',
    noOrder: 'No order',
    cancel: 'cancel',
    determine: 'determine',
    AllOrders: 'All orders',
    received: 'Goods to be received',
    antibody: 'antibody',

    Hot: 'Popular learning materials',
    addAddress: 'Add address',
    defaultAddress: 'default address',
    modify: 'modify',
    delete: 'delete',
    noAddress: 'No address yet. Click add in the upper right corner',
    sureOder: 'Confirm order product',
    productInfo: 'Product information',
    attribute: 'attribute',
    productTotal: 'Total goods',
    payble: 'Total amount payable',
    returnCart: 'Return',
    placeOder: 'place order',
    methods: {
        pay1: 'Direct payment',
        pay2: 'Cash on Delivery'
    },

    // 开票信息
    billInfo: 'Billing information',
    invoiceHeader: 'Invoice Header',
    duty: 'duty paragraph',
    iptDuty: 'Please enter tax number',
    mailbox: 'mailbox',
    iptMailbox: 'Please enter email address',
    unitName: 'Unit name',
    iptUnit: 'Please enter unit',
    Telephone: 'Telephone',
    iptPhone: 'Please enter phone number',
    deposit: 'Bank of deposit',
    iptDeposit: 'Please enter the Bank of deposit',
    iptAccountNum: 'Please enter account number',
    AccountNum: 'Account number',
    InvoiceType: 'Invoice type',
    General: 'General',
    Special: 'Special',
    personal: 'personal',
    iptInvoiceHeader: 'Please enter invoice header',
    ConfirmAdd: 'Confirm modify',
    HeaderList: 'Billing list',
    iptInvoiceType: 'Please enter invoice type',

    // 查看订单
    homePage: 'Home Page',
    viewOrder: 'View order',
    successPay: 'You have successfully paid',
    speed: 'We will deliver the goods for you at the fastest speed',
    Harvest: 'Receiving address',
    consignee: 'consignee',
    contactNum: 'contact number',
    payMethods: 'Payment method',
    payAmount: 'Payment amount',
    weChatPay: 'Wechat payment',
    Alipay: 'Alipay payment',
    UnionPay: 'UnionPay card payment',
    batch: 'Bulk purchase',
    buy: 'Article No. direct purchase',
    BatchUpload: 'Batch upload',
    CopyPaste: 'Copy paste',
    uploadAndDownload: 'Upload template download',
    goods: 'description of goods',
    iptContent: 'Input content',
    OrderPurchase: 'Order purchase',
    totalPrice: 'Generate total price',
    unitPrice: 'Generate unit price',
    BuildName: 'Build name',
    note: 'Note: in addition to re ordering GIBCO customized culture medium or siRNA detection, customized products cannot be ordered through direct purchase of article number. To order a new custom product, search the product name above for the specific design tool you need.',
    select: 'Choose an order method that best suits your needs and quickly and easily add products to the shopping cart. Visit your order history page under "my account" and click "reorder" next to the target order to quickly and easily reorder previously ordered products.',
    search: 'Item search',
    AllGoods: 'All goods',
    technicalService: 'technical service',
    LearningCenter: 'Learning Center',
    browse: 'browse',

    // 首页
    newProduct: 'Latest finished product',
    AgentInquiry: 'Agent inquiry',
    LogisticsQuery: 'Logistics query',
    appArea: 'application area',

    // 物流查询
    selectQuery: 'Please select the type you want to search, enter the corresponding content, and click query.',
    expressQuery: 'Please enter the express order number or logistics order number',
    query: 'query',
    introduce: 'This system is only used to query the logistics information of reagent consumables orders. If you want to query the logistics information of primer customization order, please contact the customer service representative.',
    product: 'product',

    // 支付成功
    paySuccess: 'Payment successful',
    returnOrder: 'Return my order',
    ViewOrder: 'View order',
    setDefault: 'Set as default',
    corporateName: 'corporate name',
    BillingTime: 'Billing time',
    AssociatedOrder: 'Associated order',
    orderTime: 'Order time',
    NoInvoiceInfo: 'No invoice information',
    CrticleNo: 'Cat.No',
    // 产品详情
    ArticleNo: 'Cat No.',
    Specifications: 'Size',
    Promotion: 'Your Price',
    stock: 'Stock',
    BuyNow: 'Buy Now',
    addCard: 'Add to Cart',
    share: 'Share',
    Collection: 'Collection',
    Collected: 'Collected',
    office: 'Your Local Office',
    commonProblem: 'Common Problems',
    technicalSupport: 'Experimental Manual',
    instructions: 'Instructions',
    ContactPay: 'Contact Purchase',
    similarProduct: 'Related Products',
    BasicAttribute: 'Datasheet',
    literature: 'Publication',
    ProductDes: 'Datasheet',
    brief: 'brief introduction',
    OrderQuantity: 'Order quantity',
    inStock: 'in stock',

    // 支付界面
    submitSuccess: 'Order submitted successfully, please pay as soon as possible',
    orderTips: 'Please complete the payment within 24 hours after submitting the order, otherwise the order will be cancelled automatically!',
    AmountPayable: 'Amount payable',
    orderDetail: 'Order detail',
    orderN: 'order Number',
    name: 'Name',
    address: 'address',
    payPlatform: 'payment platform',
    Alipay: 'Please use Alipay.',
    scan: 'Scan QR code for payment',
    weChatscan: 'Please use wechat to scan',
    expire: 'The QR code is expired ',
    refresh: ' seconds. After expiration, please refresh the page to obtain the QR code again',
    scanPay:'Please scan code to pay!',

    // 购物车
    SelectedGoods: 'Selected goods (excluding freight)',
    allSelect: 'Select all',
    Favorites: 'Favorites',
    selectDelete: 'Delete the selected item',
    putFavorites: 'Put in favorites',
    Selected: 'Selected',
    items: 'items',
    settlement: 'balance',

    inStock: 'In stock',
    outOfStock: 'Out of Stock',

    // 在线qq
    online:'Online QQ',

    // 底部公司版权
    oasis:'Oasis',
    companyName:'Zhejiang Oasis Biofarm Inc',
    aboutUs:'About Us',
    concatUs:'Contact Us',
    joinUs:'Join Us',
    copyRight:'Copy Right',
    Inquiry:'Inquiry',
};
