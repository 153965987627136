/*
 * @Description: 
 * @Version: 1.0
 * @Autor: ziwei
 * @Date: 2021-08-11 13:59:03
 * @LastEditors: ziwei
 * @LastEditTime: 2021-08-23 09:32:14
 */
import {
    Product
} from "@/api/index";
import {
    dealUrlHttp,
    showLayer,
    isHasLogin
} from '@/utils/com';

const state = {
    productList: [], //搜索一级菜单
    pageSize: 6,
    pageNo: 1,
    totalCount: 0,
    totalPage: 0,
    currentPage: 0,
    selectProductList: [], //商品列表
    selectProduct: [], //购物车选中的商品列表，或者直接下单的商品
    selectProArr: [], //购物车选中的商品列表，或者直接下单的商品
    productList: [], //购物车中商品列表
    payResult: {}, //支付结果信息
    searchProduct: [], //搜索的产品
}


const mutations = {
    setPayRs(state, payload) {
        state.payResult = payload.payResult
    },

    setProduct(state, payload) {
        state.selectProductList = payload
        state.productList = payload
    },

    setOtherInfo(state, payload) {
        for (let i in payload) {
            state[i] = payload[i]
        }
    },

    changePassword(state, userMobile) {
        state.userMobile = userMobile;
    },

    selectProduct(state, payload) {
        state.selectProduct = payload;
    },
    productList(state, payload) {
        state.productList = payload;
    },
    searchProduct(state, payload) {
        state.searchProduct = payload;
    },
    saveProduct(state, payload) {
        state.selectProduct = payload;
    },
    prevPage(state,payload){
        state.pageNo = payload
    },
    nextPage(state,payload){
        state.pageNo = payload
    }
}
const actions = {
    //产品分产品页列表
    async getProduct(context, payload) {
        let {
            pageSize,
            pageNo
        } = context.state;
        let params = {
            pageSize,
            pageNo,
            ...payload
        };
        let result = await Product.getProducts(params);
        if (result) {
            if (Array.isArray(result.records)) {
                result.records.forEach(v => {
                    v.labelArr = v.productLabel ? v.productLabel.split(',') || [] : [];
                    v.productPic = dealUrlHttp(v.productPic)
                });
            }
            context.commit('setProduct', result.records || []);
            context.commit('setOtherInfo', {
                pageNo: result.currentIndex,
                totalCount: result.totalCount,
                totalPage: result.totalPage,
                currentPage: result.currentPage
            });
        }
    },

    //收藏產品
    async takeClt(context, payload) {
        if (isHasLogin()) {
            let index = payload.index;
            let product = context.state.productList;
            let res = await Product.takeCollect(payload);
            if (res) {
                product[index].isCollection = 1;
                console.log("productproduct", product);
                context.commit('setProduct', product);
                return res;
            }
        } else {
            showLayer('toast', '请先登录');
        }
    },

    //取消收藏
    async cancelClct(context, payload) {
        if (isHasLogin()) {
            let product = context.state.productList;
            let index = payload.index;
            let res = await Product.caclCollect(payload);
            if (res) {
                product[index].isCollection = 2;
                context.commit('setProduct', product);
                return res;
            }
        } else {
            showLayer('toast', '请先登录');
        }
    }
}
const getters = {
    total(state) {
        let count = 0;
        count = state.productList.length;
        return count
    }
}
export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}