/*
 * @Description: 公用接口
 * @Version: 1.0
 * @Autor: ziwei
 * @Date: 2021-08-10 14:13:42
 * @LastEditors: ziwei
 * @LastEditTime: 2021-08-20 15:10:13
 */

import httpPost from '@/utils/axios'

class Com{
  //✔2.49首页菜单-移动上去浮动出的内容 冯壮
  static hoverCateList = data => httpPost({url:'server/bckjBizProductCategory/getCateList', data, layer:false});

  //字典表 获取菜单
  static getDic = data => httpPost({url:'/server/common/listAllDicValueByType', data});

  //✔2.50产品分类  冯壮：对应搜索内容
  static getCateList = data => httpPost({url:'/server/bckjBizProductCategory/getCateAndTwoList', data});

  //√2.30广告图片（袁怀兴完成）
  static getAdv = data => httpPost({url:'/server/bckjBizAdv/queryAdvList', data});

  //2.33.邮件订阅
  static sendEmail = data => httpPost({url:'server/bckjBizSubscribe/emailSubscribe', data});

  //√2.29.1资讯详情（袁怀兴完成）
  static newsDetail = data => httpPost({url:'/server/bckjBizNews/getOneNews', data});

  //√2.29.2新闻资讯点击数-点赞量-下载量新增（袁怀兴完成）
  static addNewsNum = data => httpPost({url:'/server/bckjBizNews/addNews', data});

  //1.2通用文件上传接口（完成-徐）
  static upFileCom = data => httpPost({url:'/server/common/uploadFile', data, contentType:'multipart/form-data'});
  // 订单详情
  static orderDetail = data =>httpPost({url:'/server/bckjBizOrder/getWebOrderDetail',data})
  //浏览记录
  static visit = data =>httpPost({url:'/server/bckjBizVisitLog/visit',data})
}

export default Com