import { Service } from '@/api/index'

export async function footerLink(data){
    let res = await Service.footerLink(data)
    return res; 
}

export async function updataNum(data) {
    let res = await Service.updataNum(data)
    return res;
}