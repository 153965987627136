import httpPost from "@/utils/axios"

class Register {
    static userRegister = data=> httpPost({url:'/server/bckjBizUser/userRegistration',data})
    static verifyCode = data => httpPost({ url: '/server/common/sendValidateCode', data });
    //修改密码
    static changePwd = data =>httpPost({url:'/server/bckjBizUser/updateUserPwd',data}) 
    //邮箱注册
    static sendEmailCode = data => httpPost({url:'/server/common/sendEmailCode',data})
   
}

export default Register 