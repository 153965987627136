/*
 * @Description: 
 * @version: 1.0
 * @Author: ziwei
 * @Date: 2021-08-21 09:50:46
 * @LastEditors: ziwei
 * @LastEditTime: 2021-08-22 17:47:46
 */
import httpPost from '@/utils/axios'

class Personal {


     // 收获地址页面接口
     // 地区选择
     static getArea = data => httpPost({
          url: '/server/bckjDicTree/getArea',
          data
     })
     // 地址详情
     static getOne = data => httpPost({
          url: '/server/bckjBizAddress/getOne',
          data
     })
     // √2.10.我的收货地址（袁怀兴完成）
     static receivingAddress = data => httpPost({
          url: '/server/bckjBizAddress/receivingAddress',
          data
     })
     // 新增收货地址
     static addAddress = data => httpPost({
          url: '/server/bckjBizAddress/updateReceivingAddress',
          data
     })
     //修改绑定手机页面接口
     static changePhone = data => httpPost({
          url: '/server/bckjBizUser/updatePhone',
          data
     })
     // 个人中心
     static personalCenter = data => httpPost({
          url: '/server/bckjBizUser/personalCenter',
          data
     })
     //修改个人资料
     static changeData = data => httpPost({
          url: '/server/bckjBizUser/updatepersonalCenter',
          data
     })
     //通用文件上传
     static uploadFile = data => httpPost({
          url: 'server/common/uploadFile',
          data
     })

     //2.60国际区号列表
     static getAreaCode = data => httpPost({
          url: 'server/bckjDicTree/getAreaCode',
          data
     });

     //修改默认地址
     static setDeftArea = data => httpPost({
          url: '/server/bckjBizAddress/updateDefaultAddres',
          data
     });

     //删除地址
     static delAdd = data => httpPost({
          url: '/server/bckjBizAddress/deleteAddress',
          data
     });

     // 我的足迹
     static logList = data =>httpPost({
          url:'/server/bckjBizVisitLog/logList',
          data
     })
}
export default Personal