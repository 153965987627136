/*
 * @Description: 产品
 * @Version: 1.0
 * @Autor: ziwei
 * @Date: 2021-08-11 15:28:33
 * @LastEditors: ziwei
 * @LastEditTime: 2021-08-23 09:54:41
 */
import httpPost from '@/utils/axios'
class Product {
    //2.36 产品分页列表 冯壮
    static getProducts = data => httpPost({
        url: 'server/bckjBizProduct/getProductPage',
        data
    });

    //产品推荐
    static rmdProducts = data => httpPost({
        url: 'server/bckjBizRecommand/getRecommandPage',
        data
    });

    //加入购物车
    static addCart = data => httpPost({
        url: 'server/bckjBizCartItem/addCartItem',
        data
    });

    //2.55 分类下的查询条件
    static getNorms = data => httpPost({
        url: 'server/bckjBizCategoryRelation/getCategoryAttr',
        data
    });

    //2.23.添加收藏
    static takeCollect = data => httpPost({
        url: 'server/bckjBizCollect/addCollect',
        data
    });

    //2.23.取消收藏
    static caclCollect = data => httpPost({
        url: 'server/bckjBizCollect/deleteMyCollectList',
        data
    });

    // ✔2.37产品详情 冯壮
    static productDetail = data => httpPost({
        url: '/server/bckjBizProduct/getProductDetail',
        data
    });

    // ✔2.39.产品展示图片列表 冯壮
    static getPictureList = data => httpPost({
        url: '/server/bckjBizProductImg/getProductImgListById',
        data
    });

    // ✔2.37.1.产品规格清单 冯壮
    static getSpecsList = data => httpPost({
        url: '/server/bckjBizSpecs/getSpecsList',
        data
    })
    // 产品推荐
    static rdmPro = data => httpPost({
        url: '/server/bckjBizRecommand/getRecommandPage',
        data
    })

    // ✔2.38产品属性 冯壮
    static getProductAttributeList = data => httpPost({
        url: '/server/bckjBizProjectAttribute/getProductAttributeList',
        data
    })

    // ✔2.37.1.产品规格清单 冯壮
    static productSpecs = data => httpPost({
        url: 'server/bckjBizSpecs/getSpecsList',
        data
    })

    //批量购买
    //2.34.批量购上传
    static batchBuy = data => httpPost({
        url: 'server/bckjBizCartItem/bulkPurchaseExcel',
        data
    });

    //2.35批量生成订单（导入成功后，选择了规格，生成订单）
    //static batchBuy = data => httpPost({ url: 'server/bckjBizOrder/batchGenerateOrder', data })
    static addCartItem = data => httpPost({
        url: 'server/bckjBizCartItem/addCartItem',
        data
    })

    //2.56批量购手动输入  fz --完成
    static addProByHand = data => httpPost({
        url: 'server/bckjBizSpecs/keyLikeSeach',
        data
    })

    //2.58批量购加入购物车
    static addCartBatch = data => httpPost({
        url: 'server/bckjBizCartItem/addBatchCartItem',
        data
    })

    //2.57批量购复制粘贴
    static copy2Buy = data => httpPost({
        url: 'server/bckjBizSpecs/bulkPurchase',
        data
    })


    //下单购买
    static createOrder = data => httpPost({
        url: 'server/bckjBizOrder/createOrder',
        data
    })

    //支付
    static aliPay = data => httpPost({
        url: '/pay/aliPay/pagePay',
        data
    })

    //是否支持货到付款
    static isCash = data=> httpPost({
        url:'/server/bckjBizUser/isCash',
        data
    })
    // 树形控件
    static filterTreeList = data =>httpPost({
        url:'/server/bckjBizCategory/filterTreeList',
        data
    })
    //相似产品
    static getProductSimilar = data =>httpPost({
        url:'/server/bckjBizSimilar/getProductSimilar',
        data
    })
    
    static contactBuy = data =>httpPost({
        url:'/server/common/listAllDicValueByType',
        data
    })
}




export default Product