import { Home } from '@/api/index'
export async function advertPicture(data){
    let res = await Home.advertPicture(data)
    return res;
}

export async function newInfo(data){
    let res =await Home.newInfo(data)
    return res;
}


export async function salesVolume(data){
    let res = await Home.salesVolume(data)
    return res;
}

export async function productClassify(data){
    let res = await Home.productClassify(data)
    return res;
}

export async function studyListByUser(data){
    let res = await Home.studyListByUser(data)
    return res;
}

export async function getProductPage(data){
    let res = await Home.getProductPage(data)
    return res;
}

export async function indexCateList(data){
    let res = await Home.indexCateList(data)
    return res;
}

export async function getNewProduct(data) {
    let res = await Home.getNewProduct(data)
    return res;
}