import httpPost from '@/utils/axios'

class Control {
    //获取发票列表
    static getBillList = data => httpPost({ url: 'server/bckjBizBill/getBillList', data })
        // 删除发票
    static deleteBill = data => httpPost({ url: 'server/bckjBizBill/deleteBill', data })
        // 新增发票抬头
    static addInvoice = data => httpPost({ url: '/server/bckjBizBillHeader/saveOrUpdateBillHeader', data })
        //修改邮箱获取验证码
    static getEmailCode = data => httpPost({ url: '/server/common/sendEmailCode', data })
        // 修改邮箱
    static updataEmail = data => httpPost({ url: '/server/bckjBizUser/updateEmail', data })
}

export default Control