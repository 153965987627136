<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: ziwei
 * @Date: 2021-08-09 14:40:28
 * @LastEditors: ziwei
 * @LastEditTime: 2021-08-19 14:12:29
-->
<template>

  <transition name="slide">
    <div v-if="show" :class="['sloading','loading-box','fx', 'p16', 'boxSize',{'back':showBack}]">
      <div class="icon-box">
        <Icon :type="type"
              :text="text"></Icon>
      </div>
      <div class="cont-box fs12 f999">{{ text }}</div>

      <div class="item">
        <div class="item-inner">
          <div class="item-loader-container">
            <div class="la-ball-clip-rotate la-2x">
              <div></div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </transition>
</template>
<script>

import Icon from "./type.js";
export default {
  components: { Icon },
  data () {
    return {
      icon: require("@/assets/img/logo.png"),
      show: false,
      type: "toast",
      text: "加载中...",
      progressColor: "#ff0000",
      color: "#999 ",
    };
  },
  computed:{
    showBack(){
      if(this.type=="error"||this.type=="toast"||this.type=="success"){
        return true
      }else{
        return false
      }
    }
  }

};

</script>

<style lang="less">
.loading-box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* box-shadow: 0 0 10px 1px #ccc; */
  z-index: 2020;
  padding: 10px;
}

.icon-box {
  flex: 0 1 70px;
  text-align: center;
  font-size: 18px;
}

.cont-box {
  flex: 1;
}

.rotate {
  animation: ani 1s linear infinite;
  display: inline-block;
  font-size: 20px;
  margin-bottom: 6px;
}

@keyframes ani {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.5s;
  top: 50%;
  opacity: 1;
}

.slide-enter,
.slide-leave-active {
  opacity: 0;
}

.icon-loading {
  font-weight: bold;
}

.default {
  color: #32b16c;
}

.error {
  color: #f00;
}

.warning {
  color: #ffa800;
}

.success {
  color: #3ac24b;
}
.f999 {
  color: #999;
}
.back{
  .cont-box{
    color: #FFF;
  }
  .icon-error{
    font-size: 20px;
  }
  border-radius: 10px;
  color: #FFF;
  padding: 15px;
  background: rgba(0, 0, 0, 0.5);
}
.iconfont{
  font-size: 0.13rem;
}
</style>