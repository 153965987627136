/*
 * @Description:学习中心
 * @Version: 1.0
 * @Autor: ziwei
 * @Date: 2021-08-09 17:32:07
 * @LastEditors: ziwei
 * @LastEditTime: 2021-08-20 19:00:37
 */
import httpPost from '@/utils/axios';

//学习中心
class Study {
  //✔2.25学习中心 冯壮
  static studyList = (data) =>
    httpPost({ url: 'server/bckjBizStudy/studyListByUser', data });

  //✔2.26学习中心-获取点击率最高的几个学习资料
  static getDjlList = (data) =>
    httpPost({ url: 'server/bckjBizStudy/getDjlList', data });

  // ✔2.27学习中心-学习详情
  static studyDetails = (data) =>
    httpPost({ url: '/server/bckjBizStudy/getOne', data });

  //  ✔2.50产品分类  冯壮
  static productSort = (data) =>
    httpPost({ url: '/server/bckjBizProductCategory/getCateAndTwoList', data });

  // 2.40.1学习中心相似产品
  static studyOther = (data) =>
    httpPost({ url: 'server/bckjBizStudyProject/getStudyAndProject', data });
}

export default Study;
