/*
 * @Description: 
 * @Version: 1.0
 * @Autor: ziwei
 * @Date: 2021-08-06 18:21:19
 * @LastEditors: ziwei
 * @LastEditTime: 2021-08-11 13:59:34
 */
import Vue from 'vue'
import Vuex from 'vuex'
import common from './modules/common'
import product from './modules/product'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
		common,
    product
	}
})
