/*
 * @Description: 
 * @Version: 1.0
 * @Autor: ziwei
 * @Date: 2021-08-10 12:55:05
 * @LastEditors: ziwei
 * @LastEditTime: 2021-08-22 18:15:59
 */
import Vue from 'vue';
let vm = new Vue();

export function showLayer(type, text, duration) {
    vm.$sLayer.open({
        type,
        text,
        duration
    });
    return true;
}

export function closeLayer() {
    vm.$sLayer.close();
}

//检查文件url是否包含http,没有返回IMGURL+url
export const dealUrlHttp = url => {
    if (url && url.indexOf("http") == -1) {
        url = process.env.VUE_APP_IMG_PATH + url;
    }
    return url;
};

//判断是够已经登录 true已登录
export const isHasLogin = () => {
    let hasLogin = false;
    const sessionId = localStorage.getItem('sessionId');
    sessionId && (hasLogin = true);
    return hasLogin
}

//判断外国国外
export const isForeign = () => {
    let isForeign = false;
    return isForeign
}

//判断是否为空
export const isEmpty = (data) => {
    if (!!!data) {
        return true;
    }
}

export const CODE = {
    INDEX: "001", //菜单对应的数据
    KANGTI: "002", //抗体
    SHIJI: "003", //试剂
    TECHCENTER: "004", //技术中心
    STUCENTER: "005", //学习中心
    TECHSERVICE: "009", //技术服务
}

//返回首页
export function goIndex(a) {
    a.$router.push('/');
}


//计算商品总数不能为0 
export function ifCountZero(arr) {
    return arr.some((v) => {
        return v.count > 0 || v.quantity > 0;
    });
}


//邮箱校验
export const validateEmail = (value) => {
    if (value === '') {
        return "请输入邮箱"
    } else {
        if (value !== '') {
            var reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
            if (!reg.test(value)) {
                return "请输入有效的邮箱"
            }
        }
    }
    return false
};


//获取文件上传
export const getFile = (event) => {
    let file = event.target.files;
    let upFile = [];
    for (var i = 0; i < file.length; i++) {
        //上传类型判断
        var imgName = file[i].name;
        var idx = imgName.lastIndexOf(".");
        if (idx != -1) {
            var ext = imgName.substr(idx + 1).toUpperCase();
            ext = ext.toLowerCase();
            if (ext != 'xlsx') {
                showLayer('toast', '请上传xlsx文件');
            } else {
                upFile.push(file[i])
            }
        } else {
            showLayer('toast', '请上传xlsx文件');
        }
    }
    return upFile;
}

export const upFile = (event) => {
        let files = event.target.files;
        return files;
    }
    /**
     * @description: 截取字符串
     * @param {*} str 原始串
     * @param {*} start 开始位置
     * @param {*} end 结束位置
     * @return {*} 如果undefined 则返回为空
     * @Date: 2021-07-30 13:51:41
     * @author: ziwei
     * @LastEditors: ziwei
     * @LastEditTime: Do not edit
     */
export function sliceStr(str, start, end) {
    if (str) {
        return str.substring(start, end)
    } else {
        return ""
    }
}

//滚动到页面顶部
export function goTop(num = 0) {
    let distance = document.documentElement.scrollTop || document.body.scrollTop; //获得当前高度
    let step = distance / 30; //每步的距离
    (function jump() {
        if (distance > 0) {
            distance -= step;
            window.scrollTo(num, distance);
            setTimeout(jump, 10);
        }
    })();
}

//商品处理左侧菜单
export function hlLeftMenu(data) {
    let arr = [];
    let index = 0;
    let y = 0;
    if (Array.isArray(data)) {
        arr = data.filter((v, i) => {
            if (v.threeCate) {
                y++;
                v.threeCate.forEach((e) => {
                    index++;
                });
                //限制展示个数
                if (y > 2 && index == 15) {
                    return index < 15;
                } else if (index > 0) {
                    return index < 30;
                }
            } else {
                if (y == 0 && index == 0) {
                    return i < 5;
                }
            }
        });
        return arr;
    }
}