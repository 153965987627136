<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: ziwei
 * @Date: 2021-08-06 18:21:19
 * @LastEditors: zmj
 * @LastEditTime: 2022-06-29 10:55:48
-->
<template>
 <div class="search">
    <div class="w shaixuan">
    <div class="w pc_fr shaixuan_rt">
      <input
        name=""
        type="text"
        v-model="searchVal"
        :placeholder="$t('plsInput')"
      /><button @click="goSearch" @keydown.enter="enterSearch"></button>
    </div>
    <div class="clear"></div>
  </div>
 </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import {getNewProduct} from './model'
export default {
  data() {
    return {
      selectOneVal: "", //一级值
      selectTwoVal: this.$route.query?.idSec || "", //二级值
      selectThreeVal: this.$route.query?.idThi || "", //三级值

      selectPageUrl: "anti", //选中跳转路径

      //搜索值
      searchVal: this.$route.query?.searchVal || "",
      proId: this.$route.query?.id || "",
      // searchProduct:'',
    };
  },

  computed: {
    ...mapState("common", ["selOne", "selTwo", "selThree"]),
    //最终搜索的值 最后一级
    searchSelVal() {
      return this.selectThreeVal || this.selectTwoVal || this.selectOneVal;
    },
  },

  created() {
    console.log(this.searchVal);
    //获取一级类别
    this.getSelOpt({ dicVal5: "", key: "selOne" }).then((res) => {
      //没有传参数 默认一级选中
      if (!this.proId) {
        this.selectOneVal = res[0].owid;
        this.getSelOpt({ dicVal5: this.selectOneVal, key: "selTwo" });
        //三级清空
        this.setgetSelOpt({ key: "selThree", result: [] });
      }
    });

    //匹配一级选中 请求二级
    if (this.proId) {
      this.selectOneVal = this.proId || "";
      this.getSelOpt({
        dicVal5: this.proId,
        key: "selTwo",
        isLeft: true,
        secId: this.selectTwoVal || "",
      });
    }
    //二级没有清空三级
    if (!this.selectTwoVal) {
      this.setgetSelOpt({ key: "selThree", result: [] });
    }
    this.enterSearch()
  },

  methods: {
    ...mapActions("common", ["getSelOpt", "getRestOpt"]),
    ...mapActions("product", ["getProduct"]),
    ...mapMutations("common", ["setgetSelOpt"]),

    //切换下拉框
    getType(type, e) {
      let index = e.target.options.selectedIndex;
      if (type == "selOne") {
        this.selectPageUrl = this.selOne[index].cateUrl || "anti";
        //查询二级
        this.getSelOpt({
          dicVal5: this.selectOneVal,
          key: this.selectOneVal ? "selTwo" : "selOne",
        });
        //二级清空
        this.selectTwoVal = "";
        !this.selectOneVal && this.setgetSelOpt({ key: "selTwo", result: [] });
        //三级清空
        this.setgetSelOpt({ key: "selThree", result: [] });
      }
      if (type == "selTwo") {
        this.getSelOpt({
          index: index == 0 ? (index = 999999) : index,
          key: "selThree",
        });
        this.selectThreeVal = "";
      }
    },

    //点击搜索
    // goSearch() {
    //   this.$router.push({
    //     path: '/newProduct',
    //     // query: {
    //     //   id: this.selectOneVal,
    //     //   idSec: this.selectTwoVal,
    //     //   idThi: this.selectThreeVal,
    //     //   searchVal: this.searchVal,
    //     // },
    //   });
    //   this.getProduct({
    //     categoryRefOwid: this.searchSelVal,
    //     key: this.searchVal || "",
    //   });

    // },
      goSearch(){
        let routeData = this.$router.resolve({
            path:'/newProduct',
            query:{search:this.searchVal,type:1}
          })
           window.open(routeData.href,'_blank')
          this.searchVal = ''
        // getNewProduct({key:this.searchVal,PageNo:1,pageSize:8}).then(res =>{
        //   let routeData = this.$router.resolve({
        //     path:'/newProduct',
        //     query:{search:JSON.stringify(res.records),type:1}
        //   })
        //   window.open(routeData.href,'_blank')
        //   this.searchVal = ''
        // })
      },
      enterSearch(){
        document.onkeydown =e=>{
         if(e.keyCode === 13 && this.searchVal !==''){
           this.goSearch()
         }
        }
      }
  },
};
</script>

<style lang="less" scoped>


</style>
