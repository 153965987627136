/*
 * @Description: 
 * @Version: 1.0
 * @Autor: ziwei
 * @Date: 2021-08-18 18:18:34
 * @LastEditors: ziwei
 * @LastEditTime: 2021-08-23 11:26:11
 */
import httpPost from '@/utils/axios'

export class Collect {
  //2.22.我的收藏列表
  static clctList = data => httpPost({
    url: 'server/bckjBizCollect/getMyCollectionList',
    data
  });
}


//我的订单
export class Order {
  //2.45订单列表（完成-徐）
  static orderList = data => httpPost({
    url: 'server/bckjBizOrder/getOrderPage',
    data
  });

  //2.54取消订单(完成-徐)
  static cancelOrder = data => httpPost({
    url: '/server/bckjBizOrder/cancelOrder',
    data
  });

  //我的收获地址
  static myAddress = data => httpPost({
    url: 'server/bckjBizAddress/receivingAddress',
    data
  });
  //申请开票
  static createBill = data => httpPost({url:'/server/bckjBizBill/createBill',data})
  //抬头列表
  static getBillHeader =data =>httpPost({url:'/server/bckjBizBillHeader/getBillHeader',data})
  //确认收货
  static sureOrder = data => httpPost({url:'/server/bckjBizOrder/confirmOrder',data})
  //物流查询
  static expressQuery = data =>httpPost({url:'/server/bckjBizOrderLog/queryOrderDelivery',data})
  //获取订单状态
  static getOrderState = data =>httpPost({url:'/server/bckjBizOrder/orderState',data})
  //发票抬头删除
  static deleteBillHeader = data =>httpPost({url:'/server/bckjBizBillHeader/deleteBillHeader',data})
  //申请退款
  static orderReturn = data =>httpPost({url:'/server/bckjBizOrderReturn/orderReturn',data})

}