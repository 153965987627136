<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: ziwei
 * @Date: 2021-08-13 09:27:52
 * @LastEditors: ziwei
 * @LastEditTime: 2021-08-19 19:18:09
-->
<template>
  <div class="w header-lb">
    <div class="w header my_nav">
      <div class="fl logo"><img src="@/assets/img/logo.png" @click="returnHome"/></div>
      <div class="fr my_nav_search"><input name=""
               type="text"
               :placeholder="this.$t('keyWords')" /><input name=""
               type="button" /></div>
      <div class="pc_fl menu">
        <a class="nav__trigger"><span class="nav__icon" @click="menuNav"></span></a>
        <nav class="nav">
          <div class="nav__list">
            <ul class="sj_dn">
              <li class="on"><a @click="goIndex">{{this.$t('homePage')}}</a></li>
              <li class="xia"><a>{{this.$t('aSetting')}}</a>
                <dl >
                  <dd><a  v-for="(item,index) in LoginAndRegister" :key="index" :class="{'active':curPath == item.url}" @click="goLoginAndRegister(item.url)">{{item.name}}</a></dd>
                </dl>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <div class="clear"></div>
    </div>
     <el-drawer :visible.sync="drawer" :with-header="true" :modal="true" close-on-press-escape size="40%">
        <span class="home-page-nav" @click="returnHome">首页</span>
        <dl class="right-menu" >
          <dt @click="hideMenu">账户设置</dt>
          <dd v-show="signOuts" v-for="(item,index) in LoginAndRegister" :key="index" @click="gopages(item.url)">{{item.name}}</dd>
        </dl>
        <dl class="right-menu-all" >
          <dt @click="hideMenuAll">全部功能</dt>
          <dd v-show="signOutAll" v-for="(item,index) in settingMenu" :key="index" @click="gopage(item.url)">{{item.name}}</dd>
        </dl>
        <span class="sign-out" @click="signOut">退出</span>
    </el-drawer>
  </div>
</template>

<script>
import { goIndex } from "@/utils/com";
export default {
  data(){
    return {
      drawer:false,
      signOuts:false,
      signOutAll:false
    }
  },
  computed:{
     LoginAndRegister () {
      return [
        {name:this.$t('data'), url: "/personal" },
        {name:this.$t('address'),url: "/address"},
        {name:this.$t('changeP'),url: "/changePassword" },
        {name:this.$t('changePhone'),url: "/changePhone" },
        {name:this.$t('mailboxs'),url: "/changeEmail" },
      ]
    },
    settingMenu(){
      return [
        {name:'我的订单',url:'/profile/order'},
        {name:'我的购物车',url:'/shoppingcart'},
        {name:'我的收藏',url:'/collect'},
        {name:'我的足迹',url:'/profile/myTracks'},
        {name:'开票信息',url:'/profile/invoice'},
        {name:'发票管理',url:'/profile/control'},
      ]
    },
    curPath(){
      return this.$route.path
    }
  },
  methods:{
      //点击返回首页
    goIndex () {
      goIndex(this)
    },
    signOut(){
      window.localStorage.clear('sessionId')
      this.$router.push('/login')
    },
    goLoginAndRegister(path){
      this.$router.push(path)
    },
    returnHome(){
      this.$router.push('/')
    },
    menuNav(){
      this.drawer = !this.drawer;
    },
    hideMenu(){
      this.signOuts = !this.signOuts
    },
    gopages(url){
      this.$router.push({path:url})
    },
    hideMenuAll(){
      this.signOutAll = !this.signOutAll
    },
    gopage(url){
      this.$router.push({path:url})
    }
  }
};
</script>

<style scoped>
.xia {
  z-index: 999;
}
.xia dl  {
  margin-left: -0.075rem;
  width: .5rem !important;
  overflow: hidden;
}

.active {
  color: #32b16c;
}
.nav__list{
  margin-top: -.25rem;
}


.el-drawer__body {
    background-color: rgba(0, 0, 0, 0.2);
}
</style>