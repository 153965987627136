<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: ziwei
 * @Date: 2021-08-06 18:21:19
 * @LastEditors: zmj
 * @LastEditTime: 2022-06-29 10:31:00
-->
<template>
  <div id="app">
    <Top :key="key + 'd'"></Top>
    <NavBar v-if="meta.showNavBar"></NavBar>
    <TopMyNav v-if="meta.topMyNav"></TopMyNav>
    <Screen :key="key + 's'" v-if="meta.showScreen"></Screen>
    <router-view :key="key" v-if="isRouterAlive"/>
    <ServiceInfo v-if="!meta.hideSerInfo"></ServiceInfo>
    <!-- <Bottom ></Bottom> -->
    <RightFix v-if=" !meta.RightFix"></RightFix>
  </div>
</template>

<script>
import Bottom from "@/components/component/Bottom.vue";
import Top from "@/components/component/Top.vue";
import NavBar from "@/components/component/NavMenu.vue";
import ServiceInfo from "@/components/component/ServiceInfo.vue";
import Screen from "@/views/index/Screen.vue";
import TopMyNav from "@/components/component/Search.vue";
import RightFix from "@/components/component/RightFix.vue";
// import {ShoppingCart} from './views/shoppingCart/model'
export default {
  // name: "App",
  components: {
    Bottom,
    Top,
    NavBar,
    ServiceInfo,
    Screen,
    TopMyNav,
    RightFix
  },
  created(){
    document.title = '欧赛思生物'
  },
  provide(){
    return {
      reload:this.reload
    }
  },
  // created(){
  //   localStorage.setItem('specsForeign',0)
  // },
  data(){
    return {
      isRouterAlive:true
    }
  },
  computed: {
    meta() {
      return this.$route.meta;
    },
    key() {
      let queryStr = JSON.stringify(this.$route.query);
      let str = this.$route.name
        ? this.$route.name + queryStr
        : this.$route + queryStr;
      return str;
    },
  },
  methods:{
    reload(){
      this.isRouterAlive = false;
      this.$nextTick(function(){
        this.isRouterAlive =true
      })
    }
  }
  // async created() {
  //   let res = await ShoppingCart();
  //   this.proList = res.records || [];
  //    this.$store.commit('product/productList',this.proList)
  // },
};
</script>
<style>
@import "./assets/css/public.css";
@import "./assets/css/style.css";
@import "./assets/css/font.css";
.ellipsis2 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.ellipsis1 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.df {
  display: flex;
}
.flex1 {
  flex: 1;
}
.active {
  color: #32b16c !important;
}
.unactive {
  color: #333;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>
