/*
 * @Description: 
 * @Version: 1.0
 * @Autor: ziwei
 * @Date: 2021-08-06 18:21:19
 * @LastEditors: zmj
 * @LastEditTime: 2022-07-01 09:42:09
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from '@/plugins/i18n'
import './assets/css/public.css'
import sLayer from './plugins/loading/index'
import './plugins/element'

Vue.use(sLayer);

Vue.config.productionTip = false

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')