import Vue from 'vue'

import {
    Upload,
    Cascader,
    Select,
    Option,
    FormItem,
    Button,
    Dialog,
    Form,
    Drawer,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Image,
    Timeline,
    TimelineItem,
    Tree,
    Input
} from 'element-ui'

Vue.use(Upload)
Vue.use(Cascader)
Vue.use(Select)
Vue.use(Option)
Vue.use(FormItem)
Vue.use(Button)
Vue.use(Dialog)
Vue.use(Form)
Vue.use(Drawer)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Image)
Vue.use(Timeline)
Vue.use(TimelineItem)
Vue.use(Tree)
Vue.use(Input)
