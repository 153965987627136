import httpPost from '@/utils/axios'

class Cart {
    static ShoppingCart = data => httpPost({ url: '/server/bckjBizCartItem/getCartList', data })

    static delProduct = data => httpPost({ url: '/server/bckjBizCartItem/deleteBatchCartItem', data })

    // 收藏
    static addCollect = data => httpPost({ url: 'server/bckjBizCollect/addCollect', data })
    // 支付宝扫码支付
    static tradePreCreatePay = data =>httpPost({url:'/pay/aliPay/pagePay',data});
    //立即购买
    static buyNow = data =>httpPost({url:'server/bckjBizOrder/buyNow',data})
}
export default Cart