import Vue from 'vue';
import VueRouter from 'vue-router';
const Login = () =>
    import ('@/views/login/Login.vue')
const Register = () =>
    import ('@/views/register/Register.vue')
const Index = () =>
    import ('../views/index/Index.vue')
const Technology = () =>
    import ('@/views/technology/Technology.vue')
const Anti = () =>
    import ('@/views/antibody/index.vue')
const Study = () =>
    import ('@/views/studyCenter/Study.vue')
const StudyList = () =>
    import ('../views/studyCenter/index.vue')
const Pay = () =>
    import ('@/views/shoppingCart/pay.vue')
const BulkBuy = () =>
    import ('@/views/bulkBuy/index.vue')
const Email = () =>
    import ('@/views/email/index.vue')
const Detail = () =>
    import ('@/views/detail/index.vue')
const ProductDetails = () =>
    import ('@/views/productDetails/productDetails.vue')
const Address = () =>
    import ('@/views/personalInfo/address/Address.vue')
const Personal = () =>
    import ('@/views/personalInfo/personal/Personal.vue')
const ChangePassword = () =>
    import ('@/views/personalInfo/changePassword/ChangePassword.vue')
const Profile = () =>
    import ('@/views/personalInfo/profile/index.vue')
const ChangePhone = () =>
    import ('@/views/personalInfo/changePhone/ChangePhone.vue')
const ChangeEmail = () =>
    import ('../views/personalInfo/changeEmail/ChangeEmail.vue')
const Collect = () =>
    import ('@/views/collect/index.vue')
const Order = () =>
    import ('@/views/personalInfo/order/index.vue')
const Control = () =>
    import ('@/views/personalInfo/control/Control.vue')
const SureCart = () =>
    import ('@/views/shoppingCart/sureCart.vue')
const ShoppingCart = () =>
    import ('@/views/shoppingCart/ShoppingCart.vue')
const InvoiceInfo = () =>
    import ('@/views/personalInfo/control/InvoiceInfo.vue')
const News = () =>
    import ('@/views/service/News.vue')
const NewsList = ()=>import('@/views/service/NewList.vue')
const NewProduct = () =>import('@/views/newProduct/NewProduct.vue')
const Logistics = ()=>import('@/views/logistics/Logistics.vue')
const ForgetPassword = ()=>import('@/views/personalInfo/forgetPassword/ForgetPassword.vue')
const PaySuccess = ()=>import('@/views/paySuccess/PaySuccess.vue')
const ViewOrder = ()=>import('@/views/personalInfo/order/ViewOrder.vue')
const NewDetail =()=>import('@/views/service/NewDetail.vue')
const BuyNow =()=>import('@/views/shoppingCart/BuyNow.vue')
const MyTracks = ()=>import('../views/personalInfo/myTracks/MyTracks.vue')
Vue.use(VueRouter);

const routes = [{
        path: '/',
        name: 'Index',
        component: Index,
        meta: {
            showScreen: true,
            showNavBar: true
        },
    },
    { //登录
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            hideSerInfo: true,
            showNavBar: true,
            RightFix:true

        },
    },
    { //注册
        path: '/register',
        name: 'Register',
        component: Register,
        meta: {
            hideSerInfo: true,
            showNavBar: true,
            RightFix:true
        },
    },
    {
        //技术服务
        path: '/technology',
        name: 'Technology',
        component: Technology,
        meta: {
            showNavBar: true,
        }
    },
    {
        //详情
        path: '/detail',
        name: 'Detail',
        component: Detail,
        meta: {
            showNavBar: true,
        },
    },
    
    {
        //产品详情
        path: '/productDetails',
        name: 'ProductDetails',
        component: ProductDetails,
        meta: {
            showNavBar: true
        }
    },
    {
        //产品-抗体
        path: '/anti',
        name: 'Anti',
        component: Anti,
        meta: {
            showScreen: true,
            showNavBar: true
        },
    },
    {
        //我的收藏
        path: '/collect',
        name: 'Collect',
        component: Collect,
        meta: {
            topMyNav: true,
        }
    },
    {
        //购物车
        path: '/shoppingcart',
        name: 'ShoppingCart',
        component: ShoppingCart,
        meta: {
                showNavBar: true
              }
           },
    {
        //学习中心
        path: '/study',
        name: 'Study',
        component: Study,
        meta: {
            showNavBar: true,
        },
    },
    {
        //学习列表
        path: '/studyList',
        name: 'StudyList',
        component: StudyList,
        meta: {
            showNavBar: true
        }
    },
    {
        //支付
        path: '/pay',
        name: 'Pay',
        component: Pay,
        meta: {
            showNavBar: true
        }
    },
    {
        //批量购
        path: '/bulkBuy',
        name: 'BulkBuy',
        component: BulkBuy,
        meta: {
            showNavBar: true
        }
    },
    {
        //邮件
        path: '/email',
        name: 'Email',
        component: Email,
        meta: {
            showNavBar: true
        }
    },
    {
        // 收货地址
        path: '/address',
        name: 'Address',
        component: Address,
        meta: {
            showNavBar: false,
        }
    },
    {
        //个人资料
        path: '/personal',
        name: 'Personal',
        component: Personal,
        meta: {
            showNavBar: false,
        }
    },
    { //修改密码
        path: '/changePassword',
        name: 'ChangePassword',
        component: ChangePassword,
        meta: {
            showNavBar: false,
        }
    },
    { //忘记密码
        path: '/forgetPassword',
        name: 'ForgetPassword',
        component: ForgetPassword,
        meta: {
            showNavBar: false,
            topMyNav:true
        }
    },
    { //修改邮箱
        path: '/changeEmail',
        name: 'ChangeEmail',
        component: ChangeEmail,
        meta: {
            showNavBar: false,
        }
    },
    { //服务
        path: '/news',
        name: 'News',
        component: News,
        meta: {
            showNavBar: true,
            topMyNav:false,
            hideSerInfo:false,
        }
    },
    { //新闻中心
        path: '/newsList',
        name: 'NewsList',
        component: NewsList,
        meta: {
            showNavBar: true,
            topMyNav:false,
            hideSerInfo:false,
        }
    },
    { //新闻中心
        path: '/newProduct',
        name: 'NewProduct',
        component: NewProduct,
        meta: {
            showScreen: true,
            showNavBar: true
        }
    },
    { //新闻中心
        path: '/newDetail',
        name: 'NewDetail',
        component: NewDetail,
        meta: {
            showNavBar: true,
            topMyNav:false,
            hideSerInfo:false,
        }
    },
    { //支付成功
        path: '/paySuccess',
        name: 'PaySuccess',
        component: PaySuccess,
        meta: {
            showNavBar: true,
            topMyNav:false,
            hideSerInfo:false,
        }
    },
    { //物流查询
        path: '/logistics',
        name: 'Logistics',
        component:Logistics
,
        meta: {
            showNavBar: true,
            topMyNav:false,
            hideSerInfo:false,
        }
    },
    { //修改手机
        path: '/changePhone',
        name: 'ChangePhone',
        component: ChangePhone,
        meta: {
            showNavBar: false,
        }
    },
    { //查看订单
        path: '/viewOrder',
        name: 'ViewOrder',
        component: ViewOrder,
        meta: {
            showNavBar: true,
        }
    },
   
    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
        children: [{
                path: 'collect',
                name: 'Component',
                component: Collect
            },
            {
                path: 'order',
                name: 'Order',
                component: Order
            },
            // {
            //     path: 'shoppingcart',
            //     name: 'ShoppingCart',
            //     component: ShoppingCart
            // },
            {
                path: 'control',
                name: 'Control',
                component: Control
            },
            {
                path: 'sureCart',
                name: 'SureCart',
                component: SureCart
            },
            {
                path: 'buyNow',
                name: 'BuyNow',
                component: BuyNow
            },
            {
                path: 'invoice',
                name: 'InvoiceInfo',
                component: InvoiceInfo
            },
            {
                path: 'myTracks',
                name: 'MyTracks',
                component: MyTracks
            }
        ],
    }

]




//解决重复点击跳转同一地址报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes,
});

// 路由导航守卫
router.beforeEach((to, from, next) => {
    document.querySelector('meta[http-equiv="keywords"]').setAttribute('content', "浙江欧赛思生物科技有限公司，神经生物学抗体，抗体和试剂盒的生产与销售，guinea pig，髓鞘染色试剂盒，转染试剂，少突胶质细胞，星形胶质细胞，小胶质细胞，神经元，sox10，ALDH1L1，MYRF，Olig2，Neun，DLX2，S100B，Ctip2，PDGFRa");
    document.querySelector('meta[http-equiv="description"]').setAttribute('content', "浙江欧赛思生物科技有限公司坐落于杭州市西湖区，核心研发团队具有深厚的神经生物学研究背景。公司主要从事抗体、生物试剂和诊断试剂盒的研发、生产与销售，同时着力打造全球领先的基因表达和检测技术研发平台，助力我国生物医学研究和临床检测服务。公司产品以”精、独、特”为特点，抗体产品目前聚焦于神经系统和干细胞生物学，品质优异，特异性高。");
    // if (
    //     to.path === '/'||to.path === '/login'||
    //     to.path === '/register'|| to.path === '/productDetails'||
    //     to.path ==='/productdetails' ||
    //     to.path === '/index'||to.path === '/study'||
    //     to.path === '/anti' ||
    //     to.path === "/email" ||
    //     to.path === "/bulkBuy" ||
    //     to.path === "/technology" ||
    //     to.path === "/detail" ||
    //     to.path === "/changePassword" ||
    //     to.path === "/newsList" ||
    //     to.path === "/newProduct" || 
    //     to.path === "/news" || 
    //     to.path ==="/forgetPassword" ||
    //     to.path ==="/newDetail" ||
    //     to.path ==="/studyList" 
    // ) return next();
    // const sessionId = localStorage.getItem('sessionId');
    // if (!sessionId) return next('/login');
    // next();


    if(to.path === '/shoppingcart' ||
       to.path ==='/collect' ||
       to.path === '/pay' ||
       to.path === '/immediatePay' ||
       to.path === '/address' ||
       to.path === '/personal' ||
       to.path === '/changeEmail' ||
       to.path === '/paySuccess' ||
       to.path === '/changePhone' ||
       to.path === '/viewOrder' ||
       to.path === '/profile' ||
       to.path === '/profile/collect' ||
       to.path === '/profile/order' ||
       to.path === '/profile/control' ||
       to.path === '/profile/sureCart' ||
       to.path === '/profile/buyNow' ||
       to.path === '/profile/invoice' 
    ){
        const sessionId = localStorage.getItem('sessionId');
    if (!sessionId) return next('/login');
    next();
    }else {
        return next();
    }
});

export default router;