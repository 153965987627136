/*
 * @Description: 
 * @Version: 1.0
 * @Autor: ziwei
 * @Date: 2021-08-16 17:39:04
 * @LastEditors: ziwei
 * @LastEditTime: 2021-08-19 15:55:10
 */
import httpPost from '@/utils/axios'

class Technical {
    // 轮播图
    static rotationChart = data =>httpPost({url:'/server/bckjBizAdv/queryAdvList',data})

    static newInfo = data=>httpPost({url:'/server/bckjBizNews/queryNewsList',data})
    
    static productSort = data =>httpPost({url:'/server/bckjBizProductCategory/getCateAndTwoList',data})

    //√2.29.2新闻资讯点击数-点赞量-下载量新增（袁怀兴完成）
    // static productSort = data =>httpPost({url:'/server/bckjBizProductCategory/getCateAndTwoList',data})

    static getProductList = data =>httpPost({url:'/server/bckjBizProduct/getProductPage',data})
}
export default Technical