/*
 * @Description:
 * @Version: 1.0
 * @Autor: ziwei
 * @Date: 2021-07-12 09:09:40
 * @LastEditors: ziwei
 * @LastEditTime: 2021-08-20 16:28:00
 */
import axios from 'axios';
import Qs from 'qs';
import pako from 'pako';
import { showLayer, closeLayer } from './com';

const ajax = axios.create({
    timeout: 20000,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    baseURL: process.env.VUE_APP_BASE_API,
    withCredentials: true,
});

ajax.interceptors.request.use((config) => {
    let token = localStorage.getItem('sessionId');
    token && (config.headers['zkUserInfoCookieName'] = token);
    return config;
});

// 正在请求中的URL
const requestingUrl = new Set();
let canRepeat = false;

function httpPost(param) {
    let { url, data, layer = true, method } = param;
    //specsForeign 国内国外 暂时写死 国内
    let specsForeign = localStorage.getItem('specsForeign')

    let params = { data: JSON.stringify({...data, specsForeign: specsForeign, specsMoney: 0 } || {}) };
    let contentType = param.contentType || 'application/x-www-form-urlencoded';
    let methodType = method || 'post';

    const ajaxPromise = new Promise((resolve, reject) => {
        const paramsStr = Qs.stringify(params);
        // 校验重复请求
        const requestItem = url + '?' + paramsStr;
        if (!canRepeat && requestingUrl.has(requestItem)) {
            // console.error('请勿重复请求URL：' + url);
            // reject({ errMsg: '请勿重复请求' });
        }
        canRepeat && (canRepeat = false);
        requestingUrl.add(requestItem);

        if (contentType === 'application/x-www-form-urlencoded') {
            params = paramsStr;
        }

        //文件上传
        if (contentType === 'multipart/form-data') {
            params = new FormData();
            for (var key in data) {
                params.append(key, data[key]);
            }
        }

        layer && showLayer('loading', 'loading');
        ajax({
            url: url,
            data: params,
            method: methodType,
        }, {
            headers: {
                'Content-Type': contentType,
            },
        }).then(
            (response) => {
                requestingUrl.delete(requestItem);
                //解码
                if (typeof response.data == 'string') {
                    response.data = JSON.parse(deBase64AndUncompress(response.data));
                }
                const retcode = response.data.backCode;
                // 0操作成功
                if (retcode === 0) {
                    closeLayer();
                    resolve(response.data.bean || response.data);
                } else if (retcode === '303' || retcode === '399') {
                    // 登录跳转
                    // 停止其他请求
                    //cancelAllAjax();
                    // 清除cookie
                    // 跳转登录去
                    //router.push('/login?fromUrl=' + location.hash.replace('#', ''));
                } else {
                    response.data.errorMess &&
                        showLayer('error', response.data.errorMess, 3000);
                    // 抛错，进入下面的onRejected || function(err){}逻辑
                    reject(response.data.errorMess || '错误'); // 若没有then函数，这句话会console.error Uncaught (in promise)
                }
            },
            (err) => {}
        );
    });
    return ajaxPromise;
}


function deBase64AndUncompress(input) {
    var destr = upCompress(input);
    return destr;
}

function upCompress(b64Data) {
    //解压后台返回的数据
    b64Data = b64Data.data || b64Data;
    //接口返回，前后都有双引号，去除双引号
    // if(b64Data.startsWith('"')){
    //     b64Data = b64Data.substr(1,b64Data.length);
    // }
    // if(b64Data.endsWith('"')){
    //     b64Data = b64Data.substr(0,b64Data.length-1);
    // }
    //base64解码
    var strData = window.atob(b64Data);
    // Convert binary string to character-number array
    var charData = strData.split('').map(function(x) {
        return x.charCodeAt(0);
    });
    // Turn number array into byte-array
    var binData = new Uint8Array(charData);
    // unzip
    var data = pako.inflate(binData);
    return Utf8ArrayToStr(data);
}
//处理utf8 中文
function Utf8ArrayToStr(array) {
    // 数据流转化为字符串, 兼容汉字
    var out = '',
        i = 0,
        len = array.length,
        char1,
        char2,
        char3,
        char4;
    while (i < len) {
        char1 = array[i++];
        // 当单个字节时, 最大值 '01111111', 最小值 '00000000' 右移四位 07, 00
        // 当两个字节时, 最大值 '11011111', 最小值 '11000000' 右移四位 13, 12
        // 当三个字节时, 最大值 '11101111', 最小值 '11100000' 右移四位 14, 14
        if (char1 >> 4 <= 7) {
            out += String.fromCharCode(char1);
        } else if (char1 >> 4 == 12 || char1 >> 4 == 13) {
            char2 = array[i++];
            out += String.fromCharCode(((char1 & 0x1f) << 6) | (char2 & 0x3f));
        } else if (char1 >> 4 == 14) {
            char2 = array[i++];
            char3 = array[i++];
            char4 = ((char1 & 0x0f) << 12) | ((char2 & 0x3f) << 6);
            out += String.fromCharCode(char4 | ((char3 & 0x3f) << 0));
        }
    }
    return out;
}

export default httpPost;