<!--
 * @Description:
 * @Version: 1.0
 * @Autor: ziwei
 * @Date: 2021-08-06 18:21:19
 * @LastEditors: zmj
 * @LastEditTime: 2022-04-18 10:35:12
-->
<template>
  <div class="sj_dn indtop">
    <div class="w">
      <div class="fl">
        <!-- <span>{{ $t("welcome") }}</span> -->
        <a
          :class="{ active: curPath == item.url }"
          @click="goLoginAndRegister(item.url)"
          v-for="(item) in LoginAndRegister"
          :key="item.i"
          >{{ item.name }}</a
        >
         <a
          :class="{ active: curPath == item.url }"
          @click="goPage(item.url, item.ndLog)"
          v-for="(item, i) in nav"
          :key="i"
        >
          <img :src="item.img" v-if="item.img" />
           <!-- <i class="count">{{($store.state.product.productList).length}}</i> -->
          {{ item.name }}
        </a>
      </div>
      <div class="fr">
        <div class="nav_la" @click="setLang">
              <a data-key="zh" :class="{'aactive':$i18n.locale =='zh' }">中</a>
              <a data-key="en" :class="{'aactive':$i18n.locale =='en' }">En</a>
        </div>
      </div>
      <div class="clear"></div>
    </div>
  </div>
</template>

<script>
import { isHasLogin, showLayer } from "@/utils/com";
export default {
   inject:['reload'],
  props: {
    activeIndex: "",
  },
  data() {
    return {
      sessionId : window.localStorage.getItem('sessionId')
    };
  },

  computed: {
    LoginAndRegister() {

      let arr = [];
      let register = { name: this.$t("freeRgster"), url: "/register" };
      let login = {
        name: isHasLogin() ? this.sessionId?this.$t("logged"):this.$t("logged") :  this.$t("plsLog"),
        url: "/login",
      };
      isHasLogin() ? (arr = [login]) : (arr = [login, register]);
      return arr;
    },
    nav() {
      return [
        // {
        //   name: this.$t("topMenu.cart"),
        //   img: require("@/assets/img/01.png"),
        //   url: "/profile/shoppingcart",
        //   ndLog: true,
        // },
        {
          name: this.$t("topMenu.myOrder"),
          url: "/profile/order",
          ndLog: true,
        },
        // {
        //   name: this.$t("topMenu.myCollect"),
        //   url: "/profile/collect",
        //   ndLog: true,
        // },
        // { name: this.$t("topMenu.purchase"), url: "/bulkBuy" },
        // { name: this.$t("topMenu.mail"), url: "/email" },
      ];
    },
    curPath() {
      return this.$route.path;
    },
  },
  async created() {
    //console.log("createdcreated",this.menuList);
  },
  methods: {
    goPage(path, ndLog) {
      if (ndLog) {
        if (!isHasLogin()) {
          showLayer("toast", "请先登录");
          window.setTimeout(() => {
            this.$router.push("/login");
          }, 1000);
          return;
        }
      }
      this.$router.push(path);
    },
    goLoginAndRegister(path) {
      this.$router.push(path);
    },
    //设置多语言
    setLang(e) {
      this.$router.go(0)
      let key = e.target.dataset.key;
      this.$i18n.locale = key;
        localStorage.setItem('locale',key)
      if(key === 'zh'){
        localStorage.setItem("specsForeign", 0);

        // window.location.href = "https://www.oasisbiofarm.cn/";
        this.reload()
      }else if(key === 'en'){
        localStorage.setItem("specsForeign", 1);
        this.reload()
        // window.location.href = "https://www.oasisbiofarm.net/";


      }
    },
  },

};
</script>

<style scoped>
.aactive{
 font-weight: bold;
}
.active {
  color: #32b16c;
}
.fl {
  position: relative;
}
.fl .count {
  display: block;
    position: absolute;
    width: 0.08rem;
    height: 0.08rem;
    background: #f55050;
    border-radius: 50%;
    color: #ffffff;
    text-align: center;
    font-size: 0.04rem;
    line-height: 0.08rem;
    top: 3px;
    left: .4rem;
}

.el-dropdown {
   margin-left: .3rem;
  cursor: pointer;
}
.el-dropdown-menu {
  z-index: 999 !important;
}
  .el-icon-arrow-down {
    font-size: 12px;
  }
  .demonstration {
    display: block;
    color: #8492a6;
    font-size: 14px;
    margin-bottom: 20px;
  }
  .nav_la a {
    display: inline-block;
    width: .2rem;
    height: .2rem;
    color: #fff;
  }
  .nav_la a{
    margin: 0 !important;
  }
  .indtop {
    height: 35px !important;
  }
</style>
