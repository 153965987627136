<!--
 * @Description:
 * @Version: 1.0
 * @Autor: ziwei
 * @Date: 2021-08-06 18:21:19
 * @LastEditors: zmj
 * @LastEditTime: 2022-04-18 10:18:03
-->
<template>
  <div>
    <div class="w header">
      <div class="fl logo"><a @click="goHome"><img src="@/assets/img/logo.png" /></a></div>
      <div class="menu menu-nav">
        <a class="nav__trigger" ><span class="nav__icon" @click="sideNav"></span></a>
        <nav class="nav">
          <div class="fr nav__list home_nav">
            <ul class="sj_dn ">
              <li
                :class="{ on: item.active }"
                @click="goPage(item)"
                @mouseenter="hoverDetail(item.dicVal5 || '', i)"
                v-for="(item, i) in getNav"
                :key="i"
              >
                <a>
                  {{ item.val }}
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <div class="clear"></div>
    </div>
   <el-drawer
   :visible.sync="drawer" :with-header="true" :modal="true" close-on-press-escape size="40%">
		<div class="nav_la" @click="setLang"><a data-key="zh">中文</a> | <a data-key="en">English</a>
    </div>
    <ul class="pc_dn cd-accordion-menu animated">
              <li :class="{ on: item.active }" @click="goPage(item)"  v-for="(item, i) in getNav" :key="i"> <a>
                  {{ item.val }}
                </a></li>
            </ul>
</el-drawer>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {

  data() {
    return {
       id: this.$route.query?.id || "" ,
        drawer:false
    };

  },
  computed: {
    ...mapState("common", ["navMenu", "othermenu"]),

    getNav() {
      return this.navMenu.map((v) => {
        let queryId = this.$route.query?.id || "";

        if (this.$route.path.replace("/", "") == (v.dicVal6 || "")) {
          v.active = true;
        } else {
          v.active = false;
        }
        if (queryId && v.dicVal5) {
          queryId != v.dicVal5 && (v.active = false);
        }

        switch (this.$i18n.locale) {
          case "en":
            v.val = v.dicVal2;
            break;
          case "zh-tw":
            v.val = v.dicVal4;
            break;
          default:
            v.val = v.dicVal2;
            break;
        }
        return v;
      });
    },

    menu() {
      console.log("this.othermenu", this.othermenu);
    },
    rightNav(){
      return[
        {name:'首页',url:'/'},
        {name:'抗体',url:'/anti'},
        {name:'试剂',url:'/BulkBuy'},
        {name:'技术服务',url:'/technology'},
        {name:'资源中心',url:'/study'},
        {name:'关于我们',url:'/news?owid=7ccda1f03d3847a98c7119abc2cc62c1'},
      ]
    }
  },

  props: {
    activeIndex: {
      type: Number,
      default: 0,
    },
  },

  async created() {
    //获取导航
    this.getNavMenu() || [];
  },

  methods: {
    ...mapActions("common", ["getNavMenu", "getRestMenu"]),
    goSearchPage(id, i) {
      let path = i.dicVal6;
      this.$router.push({
        path: "/" + path,
        query: { id: i.dicVal5, idSec: id },
      });
    },
    // sideNav(url){
    //   this.$router.push(url)
    //   this.drawer = false;
    // },
    //页面跳转
    goPage(i) {
      let url = { path: "/" + (i.dicVal6 || "") };
      if (i.dicVal5) {
        url = { ...url, query: { id: i.dicVal5 } };
      }
      this.$router.push(url);
      this.drawer =false
    },

    //悬浮菜单
    hoverDetail(dicVal5, index) {
      dicVal5 && !this.othermenu[index] && this.getRestMenu({ dicVal5, index });
    },

    //设置多语言
    setLang(e) {
      this.$router.go(0)
      let key = e.target.dataset.key;
      this.$i18n.locale = key;
        localStorage.setItem('locale',key)
      if(key === 'zh'){
        localStorage.setItem("specsForeign", 0);
        this.reload()
      }else if(key === 'en'){
        localStorage.setItem("specsForeign", 1);
        this.reload()
      }
    },
    sideNav(){
      this.drawer = !this.drawer;
    },
    // 返回首页
    goHome(){
      this.$router.push('/')
    },
    goPages(url){
      this.$router.push(url)
      this.drawer =false
    }
  },

};
</script>

<style lang="less">
.other-menu-dd {
  font-size: 0.08rem;
  padding: 0.01rem 0;
  display: inline-block;
}

.other-menu-dd:hover {
  color: #32b16c;
}

.span-space {
  color: #999;
  margin: 0 0.05rem;
  cursor: pointer;
}

.el-drawer__body {
  background-color:rgba(0, 0, 0, .8);
  opacity: 1;
  padding: 0.1rem;
  line-height: 0.35rem;
  font-size: 0.1rem;
  overflow: scroll;
  width: 2rem;
  height: calc(100% - .15rem);
}
.nav__list .sj_dn li a {
  font-size: 0.08rem;
  margin: 0 -8px;
}
.header {
  position: relative;
}
.logo {
  position: absolute;
  top: 0;
  left: 0;
}
.home_nav {
  font-weight: 700;
  padding: 0 .04rem;
  margin-left: 2.2rem !important;
}
</style>
