<!--
 * @Description: 右侧固定菜单
 * @Version: 1.0
 * @Autor: ziwei
 * @Date: 2021-08-24 12:08:46
 * @LastEditors: zmj
 * @LastEditTime: 2022-03-21 11:44:16
-->
<template>
  <div class="right_lx">
    <ul>
      
      <li class="weixin_picture">
        <a @click="slideOut"><img src="@/assets/img/weixin.png" /></a>
        <img src="@/assets/img/wx.jpg" alt=""  :class="{picture:show}" v-if="show">
      </li>
      <li class="weixin_picture">
        <a target="_blank" @click="concat"><img src="@/assets/img/83.png" /></a>
        <button class="line_qq" @click="lineConcat">{{this.$t('online')}}</button>
      </li>
      <li @click="goCart"><a><img src="@/assets/img/85.png" />
          <i v-if="isCount">{{count}}</i>
        </a>
      </li>
      <li @click="goTop"><a><img src="@/assets/img/86.png" style="background:#fff" /></a></li>
    </ul>
    
  </div>
</template>

<script>
import { goTop } from "@/utils/com";
import {updataNum } from './model'
// import { ShoppingCart } from '../../views/shoppingCart/model'
import { mapGetters } from 'vuex'
export default {
  data(){
    return {
      show:false,
      WeChat:false,
      count:'',
      sessionId:"",
      isCount:true
    }
  },
  created(){
    this.sessionId = window.localStorage.getItem('sessionId')
    this.isLogin()
    if(this.sessionId){
      this.updata()
    }
   
  },
  computed:{
    ...mapGetters('product',['total'])
  },
 
  methods:{
    isLogin(){
      this.sessionId ? this.isCount = true:this.isCount = false;
    },
    updata(){
       updataNum().then(res=>{
      if(typeof res === 'number'){
        this.count = res;
      }else {
       this.count = 0 
      }
    })
      
    },
    //返回头部
    goTop(){
      goTop();
    },
		slideOut(){
      this.show = !this.show;
    },
    mouseOut(){
      this.show = true;
    },
    mouseIn(){
      this.show = false;
    }	,
    concat(e){
      e.path[1].href = 'http://wpa.qq.com/msgrd?v=3&uin=3495875393&site=qq&menu=yes'
    }	,
    lineConcat(){
      window.open("http://wpa.qq.com/msgrd?v=3&uin=3495875393&site=qq&menu=yes")
    },
    //前往购物车
    goCart(){
      this.$router.push('/shoppingcart')
    }
  }
}
</script>
<style scoped>
  .concat {
    position: absolute;
    top: -0.3rem;
    left: -0.6rem;
  }
</style>