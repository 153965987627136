<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: ziwei
 * @Date: 2021-08-09 11:57:43
 * @LastEditors: zmj
 * @LastEditTime: 2022-03-21 11:57:31
-->
<template>
  <div class="bottom">
	<!-- <div class="bot_1"><a ><img src="../../assets/img/15.jpg" /></a><a ><img src="../../assets/img/16.jpg" /></a></div> -->
	<div class="bot_2">
    <div>{{this.$t('companyName')}}</div>
		<div><a @click="aboutUs">{{this.$t('aboutUs')}}</a>|<a @click="contactUs">{{this.$t('concatUs')}}</a>|<a @click="recruit">{{this.$t('joinUs')}}</a>|<span><a >©2021-2025{{this.$t('oasis')}}</a>{{this.$t('copyRight')}}</span></div>
		<div><a href="https://beian.miit.gov.cn/">浙ICP备2021029007号-1</a></div>
	</div>
</div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  methods:{
    aboutUs(){
      this.$router.push({path:'news?owid=7ccda1f03d3847a98c7119abc2cc62c1'})
    },
    contactUs(){
      this.$router.push({path:'news?owid=c236f50f131c472f84e4fb86ae3831f0'})
    },
    recruit(){
      this.$router.push({path:'news?owid=a967ec6a1f1f482ab89c9740439f39d7'})
    }
  }
}
</script>

<style  scoped>

</style>
