<!--
 * @Description: 底部文字提示
 * @Version: 1.0
 * @Autor: ziwei
 * @Date: 2021-08-09 16:05:57
 * @LastEditors: zmj
 * @LastEditTime: 2022-03-21 14:28:27
-->
<template>
  <div class="footer">
    <div class="w footer-service">
      <div class="left-info">
        <div class="pc_fl fot_lf">
          <div class="fot_logo">
            <img src="@/assets/img/logo.png" width="100%" />
          </div>
          <div class="fot_1">
            <p>(0571) 8196 9880</p>

            地址：{{ this.$t("companyAddress") }}<br />{{
              this.$t("detailedAddress")
            }}<br />
            {{ this.$t("technical") }}：support@oasisbiofarm.net <br />
            {{ this.$t("salesService") }}：sales@oasisbiofarm.net
          </div>
        </div>
      </div>
      <div class="center-info">
        <dl v-for="(item, index) in serviceInfo" :key="index">
          <dt>{{ item.footerCode }}</dt>
          <dd v-for="(v, index) in item.footerList" :key="index">
            <a @click="service(v.dicVal5)">{{ v.dicVal2 }}</a>
          </dd>
        </dl>
      </div>
      <div class="right-info">
        <div class="fr fot_rt">
          <p>{{ this.$t("officialQRCode") }}</p>
          <div><img src="@/assets/img/14.jpg" width="100%" /></div>
        </div>
      </div>
    </div>
    <bottom />
  </div>
</template>

<script>
import { footerLink } from "./model";
import { mapState, mapActions, mapMutations } from "vuex";
import Bottom from "./Bottom.vue";
export default {
  components: {
    Bottom,
  },
  data() {
    return {
      serviceInfo: "",
      baseUrl: "",
    };
  },
  computed: {
    ...mapState("common", ["footerMenu"]),
    setMenu() {
      let arr = [];
      this.footerMenu.forEach((v) => {});
    },
  },
  created() {
    this.baseUrl = window.location.href;
    this.footerMenu.length == 0 && this.getFootMenu({ dicType: 20007 });
    footerLink().then((res) => {
      this.serviceInfo = res;
    });
  },
  methods: {
    ...mapActions("common", ["getFootMenu"]),
    service(params) {
      this.$router.push(params);
    },
  },
};
</script>

<style scoped></style>
