/*
 * @Description:
 * @Version: 1.0
 * @Autor: ziwei
 * @Date: 2021-08-09 14:40:55
 * @LastEditors: ziwei
 * @LastEditTime: 2021-08-19 16:01:00
 */
import myLoading from './loading.vue';

export default {
  install(Vue, options) {
    const Loading = Vue.extend(myLoading);
    const Profile = new Loading({
      el: document.createElement('div'),
    });

    document.body.appendChild(Profile.$el);

    if (options) {
      if (options.txt) {
        Profile.icon = options.txt;
      }

      if (options.color) {
        Profile.color = options.color;
      }

      if (options.type) {
        Profile.type = options.type;
      }

      options.mask = options.mask ? options.mask : false;
    }

    let canClose = true;
    const myLoadingMethod = {
      open(
        opts = {
          type: '',
          text: '',
          duration: 2000,
          mask: true,
        }
      ) {
        let loadEle = document.querySelector('.sloading');
        Profile.show = true;
        if (opts.text) {
          Profile.text = opts.text;
        }

        // if (opts.color) {
        //   Profile.color = opts.color;

        // }

        if (opts.type) {
          Profile.type = opts.type;
        }

        if (opts.duration) {
          Profile.duration = opts.duration;
          if (opts.duration !== 0) {
            canClose = false;
            setTimeout(() => {
              canClose = true;
              Profile.show = false;
            }, opts.duration);
          }
        }else{
          if(opts.type=="toast"||"success"){
            canClose = false;
            setTimeout(() => {
              canClose = true;
              Profile.show = false;
            }, 1500);
          }
        }
      },

      close() {
        canClose&&(Profile.show = false);
      },
    };

    Vue.prototype.$sLayer = myLoadingMethod;
  },
};
