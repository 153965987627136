/*
 * @Description: 
 * @Version: 1.0
 * @Autor: ziwei
 * @Date: 2021-08-11 11:34:26
 * @LastEditors: zmj
 * @LastEditTime: 2022-04-18 10:18:43
 */
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import zh from '@/langs/zh'
import en from '@/langs/en'
import zhTw from '@/langs/zh-tw'

Vue.use(VueI18n)

const messages = {
  en,
  zh,
  // 'zh-tw':zhTw
}


const i18n = new VueI18n({
  locale: localStorage.getItem('locale') || 'zh',
  messages 
})


// locale.i18n((key, value) => i18n.t(key, value)) //为了实现element插件的多语言切换

export default i18n