import httpPost from '@/utils/axios'

class Home {
    static advertPicture = data => httpPost({ url: '/server/bckjBizAdv/queryAdvList', data })
    static newInfo = data => httpPost({ url: '/server/bckjBizNews/queryNewsList', data })
    static getOneNews = data => httpPost({ url: '/server/bckjBizNews/getOneNews', data })
        // static visitLogList = data =>httpPost({url:'/server/bckjBizProduct/queryProductVisitLogList',data})//未完成
        // 2.28首页中的推荐产品 冯壮 完成
    static salesVolume = data => httpPost({ url: '/server/bckjBizRecommand/getRecommandPage', data })
        // ✔2.50产品分类  冯壮
    static productClassify = data => httpPost({ url: '/server/bckjBizProductCategory/getCateAndTwoList', data })
    static studyListByUser = data => httpPost({ url: '/server/bckjBizStudy/studyListByUser', data })
        // ✔2.36 产品分页列表 冯壮
    static indexCateList = data => httpPost({ url: '/server/bckjBizCategory/indexCateList', data })
    static queryNewsList = data => httpPost({ url: '/server/bckjBizNews/queryNewsList', data })
    static getNewProduct = data =>httpPost({url:'/server/bckjBizProduct/getProductPage',data})
}

export default Home