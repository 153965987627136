/*
 * @Description:
 * @Version: 1.0
 * @Autor: ziwei
 * @Date: 2021-08-11 11:33:58
 * @LastEditors: zmj
 * @LastEditTime: 2022-05-30 10:00:19
 */
module.exports = {
  // 登录页面
  welcome: "欢迎登录欧赛思",
  loginIntro: "如果您已经有一个账户，您可以使用电子邮箱地址或手机号码登录",
  accountNum: "输入账号",
  userName: "请输入手机号或电子邮件",
  passWord: "输入密码",
  pPassword: "请输入密码",
  autoLogin: "自动登录",
  forgetPassword: "忘记密码？",
  login: "登录",
  loginTip: "若没有账号请前往注册中心",
  benefit: "创建账户有很多好处：更快的查阅，保存多个地址，跟踪订单",
  ReNow: "立即注册",

  iptComName: "请输入单位名称",
  freeRgster: "注册",
  plsLog: "登录",
  logged: "已登录",
  plsSel: "请选择",
  location: "您当前位置",
  plsInput: "请输入货号或者关键字",
  clectSuccess: "收藏成功",
  calCollect: "取消收藏成功",
  collect: "收藏",
  collected: "已收藏",
  addCard: "加购",
  pk: "对比",
  no: "货号",
  sales: "销量",
  stock: "库存",
  commodity: "商品精选",
  releaseTime: "发布时间",
  viewNum: "浏览量",
  downNum: "下载量",
  price: "价格",
  total: "共",
  page: "页",
  jumpTo: "跳转到",
  topMenu: {
    cart: "购物车",
    myOrder: "我的订单",
    myCollect: "我的收藏",
    // purchase: '批量购',
    mail: "邮件订阅",
  },
  myCar: "我的购物车",
  myOrder: "我的订单",
  myCollection: "我的收藏",
  myFootprint: "我的足迹",
  myInvoice: "我的发票",
  openBilling: "开票信息",
  invoiceAdmin: "发票管理",
  // 注册页面
  createAccount: "创建新的客户账户",
  newCome: "新手上路？",
  reBenefits1: "注册免费快捷！",
  reBenefits2: "支付更快捷",
  reBenefits3: "可保存多个运送地址",
  reBenefits4: "查看并追踪订单",
  accountReg: "账户注册",
  method1: "手机注册",
  method2: "邮箱注册",
  phone: "手机号",
  phoneReg: "请输入11位有效手机号",
  email: "电子邮箱：",
  emailReg: "请输入电子邮箱",
  password: "密码",
  passwordReg: "密码必须包含字母、数字的6至12位组合",
  surePassword: "确认密码",
  Confirm: "与密码一致",
  testCode: "验证码",
  inputCode: "请输入验证码",
  getCode: "获取验证码",
  agree: "我已阅读并同意",
  agreement: "《注册协议》",
  Reg: "立即注册",
  haveAccount: "已有账号？直接",
  log: "登录",
  companyAddress: "浙江省杭州市西湖区金蓬街321号",
  detailedAddress: "智慧谷共创园 1号楼5楼A单位",
  technical: "技术支持",
  salesService: "销售服务",
  officialQRCode: "官方二维码",

  function: "全部功能",
  homePage: "首页",
  aSetting: "账户设置",
  keyWords: "请输入关键字",
  data: "个人资料",
  address: "收货地址",
  changeP: "修改密码",
  changePhone: "修改手机",
  mailboxs: "修改邮箱",
  exitLogin: "退出登录",
  position: "您当前的位置",
  head: "我的头像",
  name: "真实姓名",
  nickname: "昵 称",
  Gender: "性 别",
  male: "男",
  female: "女",
  save: "保存修改",
  addressInfo: "地址信息",
  detail: "详细地址",
  addressTip: "请输入详细地址信息，如道路、小区、门牌号等",
  ConsigneeName: "收货人姓名",
  iptName: "请输入姓名",
  phoneTip: "电话/手机号 必填",
  setDefault: "设置为默认收获地址",
  saveAddress: "保存地址",
  newP: "新密码",
  boundP: "请输入绑定的手机号",
  newPassword: "请输入新密码",
  newPassword1: "请再次输入新密码",
  sureChange: "确认修改",
  secondGet: "秒后重新获取",
  newPhone: "新手机号",
  iptNewPhone: "请输入新手机号",
  newEmail: "新邮箱",
  iptNewEmail: "请输入新邮箱",
  userMobile: "手 机 号",
  laboratory: "实 验 室",
  direction: "研究方向",
  userAddress: "地 址",
  // 订单
  productInfo: "产品信息",
  unitPrice1: "单价",
  quantity: "数量",
  totalCount: "总计",
  state: "状态",
  operation: "操作",
  orderNum: "订单编号",
  pendingPay: "待付款",
  Paid: "已付款",
  Consignment: "待发货",
  Cancelled: "已取消",
  ViewLogistics: "查看物流",
  ExpeditingDelivery: "催发货",
  cancelOrder: "取消订单",
  ImmediatePayment: "立即付款",
  ApplyInvoic: "申请开票",
  ConfirmReceipt: "确认收货",
  noOrder: "暂无订单",
  cancel: "取消",
  determine: "确定",
  AllOrders: "全部订单",
  received: "待收货",
  antibody: "抗体",
  deleteOrder: "删除订单",

  //学习中心
  Hot: "热门学习材料",
  receiverAddress: "收货人信息地址",
  addAddress: "添加新地址",
  defaultAddress: "默认地址",
  modify: "修改",
  delete: "删除",
  noAddress: "暂无地址 点击右上角新增",
  sureOder: "确认订单产品",
  productInfo: "产品信息",
  attribute: "属性",
  productTotal: "商品总计",
  payble: "应付总金额",
  returnCart: "返回购物车",
  placeOder: "提交订单",
  methods: {
    pay1: "直接支付",
    pay2: "货到付款",
  },

  // 开票信息
  billInfo: "开票信息",
  invoiceHeader: "发票抬头",
  duty: "税号",
  iptDuty: "请输入税号",
  mailbox: "邮箱",
  iptMailbox: "请输入邮箱",
  unitName: "单位名称",
  iptUnit: "请输入单位",
  Telephone: "电话",
  iptPhone: "请输入电话",
  deposit: "开户行",
  iptDeposit: "请输入开户行",
  AccountNum: "开户账号",
  iptAccountNum: "请输入开户账号",
  InvoiceType: "发票类型",
  iptInvoiceType: "请输入发票类型",
  General: "普票",
  Special: "专票",
  personal: "个人",
  iptInvoiceHeader: "请输入发票抬头",
  ConfirmAdd: "确认修改",
  HeaderList: "开票列表",
  // iptInvoiceType: '请输入发票类型',
  compNames: "单位",
  // 查看订单
  // homePage: '首页',
  viewOrder: "查看订单",
  successPay: "您已成功付款",
  speed: "我们会以最快的速度为您安排发货",
  Harvest: "收货地址",
  consignee: "收货人",
  contactNum: "联系电话",
  payMethods: "支付方式",
  payAmount: "支付金额",
  weChatPay: "微信支付",
  Alipay1: "支付宝支付",
  UnionPay: "银联卡支付",
  batch: "批量购",
  buy: "货号直购",
  BatchUpload: "批量上传",
  CopyPaste: "复制粘贴",
  uploadAndDownload: "上传模板下载",
  goods: "货品名称",
  iptContent: "输入内容",
  OrderPurchase: "下单购买",
  totalPrice: "自动生成总价",
  unitPrice: "根据货号生成单价",
  BuildName: "根据货号生成名称",
  note: "注意：除了重新订购Gibco定制培养基或siRNA检测，定制产品不能通过货号直购方式订购。如需订购新的定制产品，请在上方通过搜索产品名称获取所需的特定设计工具。",
  select:
    "选择一种最适合您需求的订购方式，快速便捷地将产品加入购物车。访问“我的账户”下方您的订单历史页面并单击目标订单旁边的“重新订购”即可快速方便地重新订购之前订过的产品。",

  // 收藏
  search: "物品搜索",
  AllGoods: "全部商品",
  technicalService: "技术服务",
  LearningCenter: "学习中心",
  browse: "次浏览",
  RelevantInfo: "相关资料",

  // 首页
  newProduct: "最新产品",
  AgentInquiry: "代理商查询",
  LogisticsQuery: "物流查询",
  appArea: "应用领域",

  // 快递查询
  selectQuery: "请选择你要搜索的类型，并输入相应的内容，点击查询。",
  expressQuery: "请输入快递单号或者物流单号",
  query: "查 询",
  introduce:
    "此系统仅供查询试剂耗材订单物流信息。若您要查询引物定制订单物流信息，请联系客服代表。",
  product: "产品",
  paySuccess: "支付成功",
  // 支付成功
  returnOrder: "返回我的订单",
  ViewOrder: "查看订单",
  setDefault: "设为默认",
  // 发票管理
  corporateName: "公司名称",
  BillingTime: "开票时间",
  AssociatedOrder: "关联订单",
  orderTime: "订单时间",
  NoInvoiceInfo: "暂无发票信息",

  // 产品详情
  ArticleNo: "货号/包装",
  Promotion: "促销价格",
  Specifications: "规格",
  // stock: '库存',
  BuyNow: "立即购买",
  addCard: "加入购物车",
  share: "分享",
  Collection: "收藏",
  Collected: "已收藏",
  office: "您当地的办事处",
  commonProblem: "常见的问题",
  technicalSupport: "实验手册",
  instructions: "说明书",
  ContactPay: "联系购买",
  similarProduct: "相关产品",
  BasicAttribute: "基本属性",
  literature: "文献引用",
  ProductDes: "产品信息",
  brief: "简介",
  inStock: "有货",
  outOfStock: "缺货",
  OrderQuantity: "订购数量",
  CrticleNo: "型号",

  // 支付界面
  submitSuccess: "订单提交成功，请尽快支付!",
  orderTips: "请您在提交订单后24小时内完成支付，否则订单将会自动取消！",
  AmountPayable: "应付金额",
  orderDetail: "订单详情",
  orderN: "订单号",
  name: "姓名",
  payPlatform: "支付平台",
  Alipay: "请使用支付宝",
  scan: "扫描二维码支付",
  weChatscan: "请使用微信扫一扫",
  expire: "离二维码过期还剩 ",
  refresh: " 秒，过期后请刷新页面重新获取二维码",
  scanPay: "请扫码支付!",

  // 购物车
  SelectedGoods: "已选商品（不含运费）",
  allSelect: "全选",
  Favorites: "放入收藏夹",
  selectDelete: "删除选中的商品",
  putFavorites: "放入收藏夹",
  Selected: "已选择",
  items: "件商品",
  settlement: "去结算",

  // 在线qq
  online: "在线QQ",

  // 底部公司版权
  oasis: "欧赛思",
  companyName: "浙江欧赛思生物科技有限公司",
  aboutUs: "关于我们",
  concatUs: "联系我们",
  joinUs: "招聘人才",
  copyRight: "版权所有",
  Inquiry:'询价',
};
