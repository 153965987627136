/*
 * @Description:
 * @Version: 1.0
 * @Autor: ziwei
 * @Date: 2021-08-10 15:27:46
 * @LastEditors: ziwei
 * @LastEditTime: 2021-08-24 11:47:40
 */
import { Com } from '@/api/index';
import { dealUrlHttp } from '@/utils/com';

const state = {
  //头部导航
  navMenu: [],
  //悬浮头部二级菜单
  othermenu: [],

  //下拉搜索
  selOne: [],
  selTwo: [],
  selThree: [],

  //页面左侧菜单
  leftMenu: [],

  //底部菜单
  footerMenu: [],
};

const mutations = {
  setNavMenu(state, payload) {
    state.navMenu = payload;
  },
  setgetSelOpt(state, payload) {
    state[payload.key] = payload.result;
  },
  setOthermenu(state, payload) {
    state.othermenu[payload.index] = payload.result;
  },
  //根据key值自动修改state
  setOtherInfo(state, payload) {
    for (let i in payload) {
      state[i] = payload[i];
    }
  },
};

const actions = {
  //获取主nav
  async getNavMenu(context, payload) {
    let result = await Com.getDic({
      dicType: 20001,
    });
    context.commit('setNavMenu', result || []);
  },

  //✔2.50产品分类  冯壮
  async getSelOpt(context, payload = {}) {
    if (payload.key == 'selThree') {
      let three = context.state.selTwo[payload.index - 1].threeCate || [];
      context.commit('setgetSelOpt', {
        key: 'selThree',
        result: three,
      });
      return;
    }

    let result = await Com.getCateList(payload);

    //获取二级并且知道二级的id => 存储第三级
    if (payload.key == 'selTwo' && payload.secId) {
      if (Array.isArray(result)) {
        let third = result.find((v) => {
          return (v = payload.secId);
        });
        context.commit('setgetSelOpt', {
          key: 'selThree',
          result: third.threeCate || [],
        });
      }
    }

    let backData = {
      key: payload.key,
      result: result || [],
    };

    context.commit('setgetSelOpt', backData);

    if (payload.isLeft) {
      let backData2 = {
        key: 'leftMenu',
        result: result || [],
      };
      context.commit('setgetSelOpt', backData2);
    }
    return result || [];
  },

  //悬浮二级菜单
  async getRestMenu(context, payload) {
    let v = await Com.hoverCateList(payload);
    let data = {
      img: dealUrlHttp(v.icon),
      cateDesc: v.cateDesc,
      list: v.list,
    };
    context.commit('setOthermenu', {
      result: data,
      index: payload.index,
    });
  },

  //获取底部菜单
  async getFootMenu(context, payload) {
    let v = await Com.getDic(payload);
    context.commit('setOtherInfo', {
      footerMenu: v
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
